import React, { useContext, useState } from 'react';
import { BottomContent } from '../App';
import { ClientCard } from '../Constant/ClientCard';
import Banner from "../Assert/Partner/About-us-banner1.jpg"
import BannersComponent from '../Constant/BannersComponent';
import { SponsorsDataArray } from '../utils/SponsorsData';

function Clubs() {
  const UseBottomContext = useContext(BottomContent);
  const [searchContent, setSearchContent] = useState("");
  const [paginatedArray, setPaginatedArray] = useState([])

  const onChangeSearchContent = (e) => {
    let content = e.target.value;
    setSearchContent(content);
    let temp = SponsorsDataArray.filter((e) =>
      e.title.toLowerCase().match(content.toLowerCase())
    );
    setPaginatedArray(temp)
  };
  return (
    <div ref={UseBottomContext} className='flex flex-col  '>
      <BannersComponent Banner={Banner} />
      <div className='w-full bg-[#1A1A1A] py-10'>
        <div className=' max-w-[1400px] mx-auto bg-[#1A1A1A]  flex lg:flex-row flex-col lg:items-center gap-5 p-3 justify-between'>
          <p className='text-white lg:w-[80%]'>Register your club with us to feature your club, riders, experiences with the commune. Be the first to get news and information on our activities. Open up privileges to our activities, events and rides. Showcase your experience and expertise to the commune, get exclusive offers on riding gears, pick up customised merchandise and more.
          </p>
          <button className='p-2 px-5 text-lg bg-[#E81938] text-white font-semibold rounded-full'>Click here to Register</button>
        </div>
      </div>
      <div className='max-w-[1400px] mx-auto p-3 flex flex-col gap-10 py-16'>
        <h3>REGISTERED CLUBS</h3>
        <h3 className='text-[21px]'>ride with us</h3>
        <input value={searchContent} onChange={(e) => onChangeSearchContent(e)} placeholder='SEARCH YOUR CLUB - ENTER THE NAME OF CLUB' className='border-b-2 border-0 outline-none outline-0 placeholder:text-2xl placeholder:text-center placeholder:font-bold' />
        <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5">
          {searchContent.length == 0 ? SponsorsDataArray.map(item => (
            <ClientCard img={item.img} title={item.title} />
          )) :
            searchContent.length > 0 ? paginatedArray.map(item => (
              <ClientCard img={item.img} title={item.title} />
            )) : null}
        </div>
      </div>
    </div>

  )
}

export default Clubs;