import React, { useContext } from 'react'
import { BottomContent } from '../../App';
import BannersComponent from '../../Constant/BannersComponent';
import Banner from "../../Assert/Home/home-banner.jpg"

function ShippingPolicy() {
  const UseBottomContext = useContext(BottomContent);

  return (
    <div>
      <BannersComponent Banner={Banner} name="Shipping & Delivery Policies"/>
      <div ref={UseBottomContext} className='py-10'>
        <div className='max-w-[1400px] mx-auto p-3 flex flex-col gap-3'>
          <div className='text-[#3e3e3e] text-xl font-medium'>Shipping & Delivery Policies

          </div>
          <p>
          Big Biking Commune Pvt Ltd ships its products to almost all parts of India. Orders placed will be shipped within 24* hrs. We ship on all days except Sunday and National Holidays. For all areas serviced by reputed couriers, the delivery time would be within 3 to 4 business days of shipping (business days exclude Sundays and other holidays). For other areas the products will be shipped through Indian Post and may take 1-2 weeks depending on location. At times there might be unexpected delays in the delivery of your order due to unavoidable and undetermined logistics challenges beyond our control for which Big Biking Commune is not liable and would request its users to cooperate as BBC continuously tries to nought such instances. Also, BBC reserves the right to cancel your order at its sole discretion in cases where it takes longer than usual delivery time or the shipment is physically untraceable and refund the amount paid for cancelled product(s) to your source account.
          </p>
        </div>
      </div>
    </div>
  )
}

export default ShippingPolicy