import React, { useContext, useState } from 'react';
import { AboutCompany, OurProfile } from '../utils/AboutCompany';
import fadeIn from '../variants';
import { BusinessGoals, WhatWeDoArray } from '../utils/BusinessGoals';
import BannersComponent from '../Constant/BannersComponent';
import { BottomContent } from '../App';
import Banner from "../Assert/About/About-us-banner3.jpg"

function About() {
  const UseBottomContext = useContext(BottomContent);

  return (
    <div ref={UseBottomContext} className='flex flex-col'>
      <BannersComponent Banner={Banner} />
      <div className='py-16 overflow-hidden'>
        <div className='max-w-[1400px] mx-auto p-3 flex flex-col gap-5'>
          <h3  >About Us</h3>
          {AboutCompany.map((item, index) =>
            <section key={index} className='grid lg:grid-cols-3 grid-cols-1 gap-5'>
              <div className='lg:col-span-2 col-span-1 flex flex-col space-y-5'>
                <p >{item.description1}</p>
                <p >{item.description2}</p>
                <p >{item.description3}</p>
              </div>
              <div className='col-span-1 flex flex-col overflow-hidden h-[380px]'>
                <img  className='w-full h-full object-cover hover:scale-105 duration-300 rounded-sm' src={item.img} />
              </div>
            </section>)}
        </div>
      </div>
      <div className='bg-[#F1F1F1] overflow-hidden py-10'>
        <div className='max-w-[1400px] mx-auto p-3 flex flex-col gap-10'>
          <h3  >CONNECT WITH THE COMMUNE.</h3>
          <h4  >Reach out to us, lets join hands to grow the motorcycling community, find out your Motorcycling Mojo with us, together we can grow the commune.
          </h4>
          <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6'>
            {BusinessGoals.map((item, index) =>
              <div key={index}  className='col-span-1 rounded-md bg-white shadow'>
                <div className='h-[55%] overflow-hidden'>
                  <img className='rounded-t-md w-full h-full object-cover transition-all hover:scale-y-150 duration-1000' src={item.img} />
                </div>
                <div className='flex flex-col space-y-4 p-4 h-[45%]'>
                  <div className='text-2xl float-left   text-[#e81938] font-bold uppercase'> {item.title}</div>
                  <p>{item.description} </p>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='bg-[#1A1A1A] overflow-hidden py-10'>
        <div className='max-w-[1400px] mx-auto p-3 flex flex-col gap-10'>
          <h3  className='text-white'>What We Do</h3>
          <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6'>
            {WhatWeDoArray.map((item, index) =>
              <div key={index}  className='col-span-1 flex flex-col space-y-10 p-10 rounded-md bg-white shadow hover:bg-[#131313] transition-all min-h-[520px] relative float-left'>
                <img className='w-32 object-contain' src={item.img} />
                <div className='text-2xl float-left   text-[#e81938] font-bold uppercase'> {item.title}</div>
                <p className='hover:text-white'>{item.description} </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='py-16 overflow-hidden'>
        <div className='max-w-[1400px] mx-auto p-3 flex flex-col gap-5'>
          <h3  >Our Profile</h3>
          {OurProfile.map((item, index) =>
            <section key={index} className='grid lg:grid-cols-3 grid-cols-1 gap-5'>
              <div className='lg:col-span-2 col-span-1 flex flex-col space-y-5'>
                <div >{item.title}</div>
                <p >{item.description1}</p>
                <p >{item.description2}</p>
                <p >{item.description3}</p>
              </div>
              <div className='col-span-1 flex flex-col overflow-hidden'>
                <img  className='w-full h-full object-cover rounded-sm' src={item.img} />
              </div>
            </section>)}
        </div>
      </div>

    </div>
  )
}

export default About;