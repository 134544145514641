import React, { useContext, useState } from 'react';
import { AboutCompany, OurProfile } from '../utils/AboutCompany';
import fadeIn from '../variants';
import { BusinessGoals, WhatWeDoArray } from '../utils/BusinessGoals';
import BannersComponent from '../Constant/BannersComponent';
import { BottomContent } from '../App';
import Banner from "../Assert/contact-banner-1.png"
import { FaMap, FaPhone } from 'react-icons/fa';
import { FaMailchimp } from 'react-icons/fa6';
import { IoMdMail } from "react-icons/io";

function Contact() {
    const UseBottomContext = useContext(BottomContent);
    const aboutCompany = [
        {
            img1: <FaPhone className='text-white text-5xl' />,
            header: "Call Us",
            headerDescription: "+91 7337657741",
        },
        {
            img1: <IoMdMail className='text-white text-5xl' />,
            header: "Sent Us Email",
            headerDescription: "sales@bigbikingcommune.com",
        },
        {
            img1: <FaMap className='text-white text-5xl' />,
            header: "Meet Us At",
            headerDescription: "Big Biking Commune #402 , 1st D Main,7th Cross, Domlur Layout, Bangalore – 560071",
        },
    ]

    const [name, setName] = useState("");
    const [message, setMessage] = useState("");
    const [mobile_number, set_mobile_number] = useState("")
    const onChangeMobileNumber = (e) => {
        if (e.target.value.length <= 10) {
            set_mobile_number(e.target.value);
        }
        else {
            console.log(e.target.value)
        }
    }

    return (
        <div ref={UseBottomContext} className='flex flex-col'>
            <BannersComponent Banner={Banner} />
            <div className='py-16'>
                <div className='max-w-[1400px] mx-auto p-3 flex flex-col gap-5'>
                    <section className='grid lg:grid-cols-3 grid-cols-1 gap-5'>
                        <div className='col-span-1 flex flex-col gap-5 rounded-lg bg-[#141414] h-[400px] justify-between text-white lg:p-10 p-3'>
                            {
                                aboutCompany.map((item, index) =>
                                    <div className='flex flex-row gap-10 items-center'>
                                        <div>{item.img1}</div>
                                        <div className='flex flex-col gap-3'>
                                            <div className='text-2xl font-medium'>{item.header}</div>
                                            <div>{item.headerDescription}</div>
                                        </div>
                                    </div>
                                )}
                        </div>
                        <div className='lg:col-span-2 col-span-1 flex flex-col space-y-5 rounded-lg bg-white shadow-lg p-5 border'>
                            <button className='p-3 text-2xl bg-[#E81938] text-white font-bold'>LET'S COMMUNICATE AND SAY HELLO.</button>
                            <p>"*" indicates required fields</p>
                            <input value={name} onChange={(e) => setName(e.target.value)} type='text' placeholder='Your Name *' className=' outline-none focus:border hover:border-[#104cba] transition-all duration-200 focus:border-[#104cba]' />
                            <input value={mobile_number} onChange={(e) => onChangeMobileNumber(e)} type='text' placeholder='Phone No *' className=' ' />
                            <input value={mobile_number} onChange={(e) => onChangeMobileNumber(e)} type='number' maxLength={10} placeholder='Phone No *' className=' ' />
                            <textarea value={message} onChange={(e) => setMessage(e.target.value)} placeholder='Message *' className='textarea' />
                            <div>
                                <button className='p-2 px-5 text-lg bg-[#E81938] text-white font-semibold rounded-full'>Submit</button>
                            </div>
                        </div>

                    </section>
                    <div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11189513.391900778!2d72.3557353707999!3d14.463457909362486!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae158a4c96e9a1%3A0x808d44bfc8ed0c0d!2sBig%20Biking%20Commune!5e0!3m2!1sen!2sin!4v1732904601184!5m2!1sen!2sin" width="100%" height="450" style={{ borderRadius: "10px" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>                    
                    </div>
                </div>
            </div>


        </div >
    )
}

export default Contact;