import React from 'react'


function BannersComponent(props) {
    return (
        <div style={{ backgroundImage: `url(${props.Banner})` }} className='bg-no-repeat bg-cover flex flex-col space-y-5 items-center justify-center bg-center lg:h-[50vh] h-[30vh]'>
            <h3 className='text-white'>{props.name}</h3>
        </div>
    )
}

export default BannersComponent