import React, { useRef } from 'react'
import { GrFacebookOption } from "react-icons/gr";
import { FaTwitter } from "react-icons/fa";
import { FaPinterestP } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { BiLogoLinkedin } from 'react-icons/bi';

function Footer({ handleClick }) {
    // const SocialMedia = [
    //     {
    //         icon: <GrFacebookOption />,
    //         color: "#395398"
    //     },
    //     {
    //         icon: <FaTwitter />,
    //         color: "#1E94E9"
    //     },
    //     {
    //         icon: <BiLogoLinkedin />,
    //         color: "#046EB0"
    //     },
    //     {
    //         icon: <FaPinterestP />,
    //         color: "#BB2032"
    //     }
    // ]
    const FooterMeneu = [
        {
            name: "Terms & Conditions",
            route: "TermsConditions"

        },
        {
            name: "Privacy Policy",
            route: "/PrivacyPolicy",
        },
        {
            name: "Cookie Policy",
            route: "/CookiePolicy"
        },
    ]
    const Terms = [
        {
            name: "Refund and Cancellation",
            route: "RefundPolicy"

        },
        {
            name: "Cancellation Policy",
            route: "CancellationPolicy"

        },
        {
            name: "Shipping & Delivery Policies",
            route: "DeliveryPolicy"

        },
    ]
    const Info = [
        {
            name: "About Us",
            route: "/About",
        },
        {
            name: "Events",
            route: "/Events",
        },
        {
            name: "Contact",
            route: "/Contact",
        },
    ]

    return (
        <div style={{ backgroundImage: `url(${require("../Assert/About/foot-bg.jpg")})` }} className='bg-no-repeat bg-cover bg-fixed flex flex-col items-center justify-center'>
            <div className='max-w-[1400px] mx-auto p-3 flex flex-col gap-10 py-16'>
                <h3 className='text-center text-white'>DO YOU HAVE QUESTIONS?<br /> DO NOT WAIT,<span className='text-[#e81938]'> LET’S TALK</span></h3>
                <div className='grid lg:grid-cols-7 grid-cols-1 gap-6'>
                    <div className='col-span-1 flex flex-col  gap-3  text-white text-base relative p-2  px-5'>
                        <img src={require("../Assert/Logo.png")} className='w-[250px] object-contain h-24' alt='Logo' />
                        {/* <div className='flex flex-row items-center gap-5'>
                            <div className={`flex flex-col items-center justify-center p-[10px] cursor-pointer bg-[#395398] rounded-full hover:opacity-75 hover:bg-[#395398] hover:duration-200`}>
                                <a href=''>
                                    <GrFacebookOption />
                                </a>
                            </div>
                            <div className={`flex flex-col items-center justify-center p-[10px] cursor-pointer bg-[#1E94E9] rounded-full hover:opacity-75 hover:bg-[#1E94E9] hover:duration-200`}>
                                <a href=''>
                                    <FaTwitter />
                                </a>
                            </div>
                            <div className={`flex flex-col items-center justify-center p-[10px] cursor-pointer bg-[#046EB0] rounded-full hover:opacity-75 hover:bg-[#046EB0] hover:duration-200`}>
                                <a href=''>
                                    <BiLogoLinkedin />
                                </a>
                            </div>
                            <div className={`flex flex-col items-center justify-center p-[10px] cursor-pointer bg-[#BB2032] rounded-full hover:opacity-75 hover:bg-[#BB2032] hover:duration-200`}>
                                <a href=''>
                                    <FaPinterestP />
                                </a>
                            </div>

                        </div> */}
                    </div>
                    <div className='col-span-2 flex flex-col gap-2  text-white text-base'>
                        <div className='text-[#e81938] text-2xl font-bold uppercase float-left mt-3'>Our Contacts</div>
                        <div className='text-base float-left font-light block relative'>Phone: +91 733 765 7741</div>
                        <div className='text-base float-left font-light block relative'>E-mail: sales@bigbikingcommune.com </div>
                        <div className='text-base float-left font-light block relative'>Location: #402 , 1st D Main,7th Cross, </div>
                        <div className='text-base float-left font-light block relative'>Domlur Layout,</div>
                        <div className='text-base float-left font-light block relative'>Bangalore – 560071</div>
                    </div>
                    <div className='col-span-1 flex flex-col gap-4'>
                        <div className='text-[#e81938] text-2xl font-bold uppercase float-left mt-3'>Quick Links </div>
                        {FooterMeneu.map((header, index) =>
                            <div onClick={() => handleClick(header.route)} key={index}>
                                <Link to={header.route} className='text-base float-left font-light block relative cursor-pointer text-white'> {header.name}</Link>
                            </div>
                        )}
                    </div>
                    <div className='col-span-2 flex flex-col gap-4'>
                        <div className='text-[#e81938] text-2xl font-bold uppercase float-left mt-3 '>Policy</div>
                        {Terms.map((Policy, index) =>
                            <div onClick={() => handleClick(Policy.route)} key={index}>
                                <Link to={Policy.route} className='text-base float-left font-light block relative text-white'> {Policy.name}</Link>
                            </div>
                        )}
                    </div>
                    <div className='col-span-1 flex flex-col gap-4'>
                        <div className='text-[#e81938] text-2xl font-bold uppercase float-left mt-3 '>Info</div>
                        {Info.map((Policy, index) =>
                            <div onClick={() => handleClick(Policy.route)} key={index}>
                                <Link to={Policy.route} className='text-base float-left font-light block relative text-white'> {Policy.name}</Link>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='w-full text-white text-center  bg-[#E81938]'>
                <div className='max-w-[1400px] mx-auto p-3 h-full w-full text-white flex flex-row items-center justify-between  bg-[#E81938]'>
                    <div className='text-base float-left font-light block relative'>2023 Big Biking Commune © All Rights Reserved
                    </div>
                    <div className='text-base float-left font-light block relative'>Powered by AGT</div>
                </div>
            </div>
        </div>
    )
}

export default Footer