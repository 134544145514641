import React, { useContext } from 'react'
import { BottomContent } from '../../App';
import BannersComponent from '../../Constant/BannersComponent';
import Banner from "../../Assert/Home/home-banner.jpg"

function CookiePolicy() {
  const UseBottomContext = useContext(BottomContent);

  return (
    <div>
      <BannersComponent Banner={Banner} name="Cookie Policy"/>
      <div ref={UseBottomContext} className='py-10'>
        <div className='max-w-[1400px] mx-auto p-3 flex flex-col gap-3'>
          <div className='text-[#3e3e3e] text-xl font-medium'>COOKIE POLICY</div>
          <p>Bigbikingcommune.com operates a strict privacy policy and we are committed to being transparent about how we use cookies on our website.</p>
          <p>Why are cookies important? Cookies help you make your online experience more efficient and relevant to your interests. For instance, they are used to remember your preferences on sites you visit often, to remember your user ID and the contents of your shopping baskets, and to help you navigate between pages efficiently.</p>
          <p>What is a Cookie? A cookie is a small file, or files on your computer, phone, or other device with a browser to save snippets of text for reference by the website you are visiting. All cookies have expiration dates in them that determine how long they stay in your browser:
          </p>
          <p>Session cookies - these are temporary cookies that expire (and are automatically erased) whenever you close your browser.
          </p>
          <p>Persistent cookies - these usually have an expiration date and so stay in your browser until they expire, or until you manually delete them. For example we use persistent cookies to better understand usage patterns so that we can improve the site for our customers.
          </p>
          <p>Cookies are grouped into the following categories:
          </p>
          <p>Essential - these are cookies that are required for the regular operation of our websites.
          </p>
          <p>Functional - these remember your preferences, and are intended to make your experience on our websites better for you.
          </p>
          <p>Analytics – these cookies are used for performance measurement to understand things including how many people visit our websites, how they navigate our sites, and what content is popular. This allows us to improve your experience with us. Additionally, you can see how Google Analytics (one of our analytics tools) uses cookie information when you use our partners' sites by visiting www.google.com/policies/privacy/partners, or any other URL Google may provide from time to time
          </p>
          <p>Advertising - these cookies enable us and our advertising partners to serve you with relevant advertisements that we think will interest you. You might see these advertisements on our sites on other sites you visit. These cookies record your visit to our website and the content you interact with. They may be placed by us, or by advertising partners with our permission. To ensure compliance with our policies, we restrict the use of third-party cookies to trusted partners. We also use third party information from third party sources to enable us deliver advertising. These sources are validated by Third party, and not by us. To delete cookies from your browser, please note the following simple steps. The following steps are indicative for Google chrome and might vary of the different browsers that you may use. Please refer Settings on your browser for further details. To ensure compliance with our policies, we restrict the use of third-party cookies to trusted partners. You may also change other setting related to Privacy and Security under the same section.
          </p>
        </div>
      </div>
    </div>
  )
}

export default CookiePolicy