import React, { useContext } from 'react';
import { BottomContent } from '../App';
import Banner from "../Assert/Blogs/government-board.jpg"
import { GovernmentBoardsArray } from '../utils/HomeData';
import BannersComponent from '../Constant/BannersComponent';

function GovernmentBoards() {
  const UseBottomContext = useContext(BottomContent);
  return (
    <div ref={UseBottomContext} className='flex flex-col'>
      <BannersComponent Banner={Banner} />
      <div className='bg-[#ECECEC] overflow-hidden py-10 '>
        <div className='max-w-[1200px] mx-auto p-3 flex flex-col gap-5'>
          <div className='text-[#3e3e3e] text-xl font-medium text-center'>
            We thank the different tourism boards that have supported our initiaitves promoting Motorcycle tourism rides and events.
            <span> Contact us</span> to explore new destination promotion initiatives.
          </div>
          <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6'>
            {GovernmentBoardsArray.map((item, index) =>
              <div key={index} className='bg-white shadow-lg rounded-md p-5'>
                <img className='w-full h-full object-contain rounded-md' src={item.img} />
              </div>
            )}
          </div>
        </div>
      </div>

    </div>
  )
}


export default GovernmentBoards;