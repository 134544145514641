import React, { useContext } from 'react';
import { BottomContent } from '../App';
import { ClientCard } from '../Constant/ClientCard';
import Banner from "../Assert/Partner/sponsors-banner.jpg"
import { SponsorsDataArray } from '../utils/SponsorsData';
import BannersComponent from '../Constant/BannersComponent';

function Sponsors() {
    const UseBottomContext = useContext(BottomContent);

    return (
        <div ref={UseBottomContext} className='flex flex-col '>
            <BannersComponent Banner={Banner}/>
            <div className='max-w-[1400px] mx-auto p-3 flex flex-col gap-5'>
                <h3>Sponsors </h3>
                <h3 className='text-[1.25rem] font-medium'>We are thankful to our partners / sponsors for supporting our rides, events and experiences. Contact us for more collaborative initiatives.        </h3>
                <div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-5">
                    {SponsorsDataArray.map(item => (
                        <ClientCard img={item.img} title={item.title} />
                    ))}
                </div>
            </div>
        </div>

    )
}
export default Sponsors