import React, { createContext, useRef, useState } from 'react'
import Home from './Components/Home';
import Header from './Components/Header';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './Components/About';
import Contact from './Components/Contact';
import Footer from './Components/Footer';
import Bottom from './Components/Bottom';
import RefundPolicy from './Components/Policy/RefundPolicy';
import PrivacyPolicy from './Components/Policy/PrivacyPolicy';
import TermsandConditions from './Components/Policy/TermsandConditions';
import CookiePolicy from './Components/Policy/CookiePolicy';
import Gallery from './Components/Gallery';
import PressReleases from './Components/PressReleases';
import Blogs from './Components/Blogs';
import GovernmentBoards from './Components/GovernmentBoards';
import Clubs from './Components/Clubs';
import Sponsors from './Components/Sponsors';
import CancellationPolicy from './Components/Policy/CancellationPolicy';
import ShippingPolicy from './Components/Policy/ShippingPolicy';

export const BottomContent = createContext();

function App() {

  const ref = useRef(null);

  const handleClick = () => {
    if (ref.current) {
      const top = ref.current.getBoundingClientRect().top + window.scrollY - 100; // Adjust for 100px offset
      window.scrollTo({ top, behavior: 'smooth' });
    }
  };


  const [showHeader, setShowHeader] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [selectedActive, setSelectedActive] = useState("Home");
  const onClickNavigation = (_route_name) => {
    if (selectedActive == _route_name) {
      setSelectedActive("");
    }
    else {
      setSelectedActive(_route_name);
      if (ref.current) {
        const top = ref.current.getBoundingClientRect().top + window.scrollY - 100; // Adjust for 100px offset
        window.scrollTo({ top, behavior: 'smooth' });
      }
      // ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
    setShowHeader(false)
  }

  return (
    <BottomContent.Provider value={ref}>
      <BrowserRouter>
        <Header selectedActive={selectedActive} setSelectedActive={setSelectedActive} showHeader={showHeader} showSearch={showSearch} setShowHeader={setShowHeader} setShowSearch={setShowSearch} onClickNavigationRoute={onClickNavigation} />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/About' element={<About />} />
          <Route path='/PressReleases' element={<PressReleases />} />
          <Route path='/Blog' element={<Blogs />} />
          <Route path='/Government' element={<GovernmentBoards />} />
          <Route path='/Sponsors' element={<Sponsors />} />
          <Route path='/Gallery' element={<Gallery />} />
          <Route path='/Clubs' element={<Clubs />} />
          <Route path='/Contact' element={<Contact />} />
          <Route path='/RefundPolicy' element={<RefundPolicy />} />
          <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
          <Route path='/TermsConditions' element={<TermsandConditions />} />
          <Route path='/CookiePolicy' element={<CookiePolicy />} />
          <Route path='/CancellationPolicy' element={<CancellationPolicy />} />
          <Route path='/DeliveryPolicy' element={<ShippingPolicy />} />
          <Route path='/Contact' element={<Contact />} />
        </Routes>
        <Bottom handleClick={handleClick} />
        <Footer handleClick={handleClick} />
      </BrowserRouter>
    </BottomContent.Provider>
  )
}

export default App;