import AboutImage from '../Assert/About/connect-vlogger-motorcycling.jpg';
import ProfileImage from '../Assert/About/Arun-scaled.jpg';

export const AboutCompany = [
    {
        img: AboutImage,
        description1: "The Big Biking Commune is the one of the largest aggregator platform in the motorcycling community; an amalgam spread across the touring, cruising, off-road, adventure & Motorsports category.",
        description2: "Big Biking Commune has emerged as the largest, strongest and the most trusted network of motorcycle riders across regions bonding them as a single family. Our network is spread across Motorcycle Clubs, Solo bikers, Group riders reaching out to more than 50000 passionate bikers. This platform brings together bikers, motorcycles brands, riding clubs, accessories and gears and all related products and services under a single roof. We create and curate experiences for the community to celebrate being a biker and to network and learn from experienced bikers in the commune.",
        description3: "Join us to discover new bikes, biking routes, biking buddies and riding and celebrating the essence of being a biker.",
    },
]
export const OurProfile = [
    {
        img: ProfileImage,
        title: "Arun Kumar Chief Convener & Founder Big Biking Commune Pvt Ltd",
        description1: "A self made business man and an entrepreneur at heart who was driven by the passion to create and curate experiences for Corporate brands in the events and activation space. Having started Below the Line activations and events back in the late 1990’s, he has worked long and hard to build one of the largest activation companies in India with branches in 5 cities and a staff strength of around 30 people.",
        description2: "Over the last 20 years he has built his activation agency Axiom Gen Nxt India Pvt Ltd into one of the leading player in the industry with a pan India footprint serving marquee Indian and MNC clients in the FMCG, Auto, IT, BFSI, Realty and Corporate brand sectors. Some of the popular brands worked with are Unilever, P&G, Darbur, Britannia, Kellogg's, Cadbury, TVS Motors, Honda Motorcycle, Hero Motocorp, Tata Motors, Suzuki, Airtel, Tata Docomo, Aircel , Hathway, HP, EXL, Volvo IT, Tarnea Solutions, ICICI , Canara Bank, Bharath Matrimony and many more.",
        description3: "While still running the most successful home grown integrated event management firm, the entrepreneurial bug bit him a second time. Big Biking Commune Pvt Ltd was born from that passion to create and curate experiences for the bikers and the Motorcycling industry. Today Big Biking Commune has become his most passionate work and he devotes maximum time to shape and structure the fledging brand and realize its potential."
    },
]