export const BigBikingCommune = [
    {
        img: require("../Assert/Home/about-section.jpg"),
        description1: "The Big Biking Commune is the ‘Go To Destination’ that brings together passionate bikers, motorcycle brands, riding clubs, Accessories, Custom and Vintage bikes, bike rentals, bike tour operators and all related products and services under a single roof.",
        description2: "We are a celebration of the bonding, belonging and brotherhood that strings us all together as a single family; it is the perfect pit stop for the motorcycle community to revel, rejoice and regroup as we plot the next journey. Together, we shall 'Rule the Roads'.",
        description3: "As a Commune we are all about creating and curating new experiences for the motorcycling community; putting together events and entertainment to celebrate being a biker; networking and learning from experienced motorcyclists; checking out new motorcycles, discovering new biking trips and routes, meeting with new biking buddies and riding out together celebrating the essence of being a biker.",
    },
]
export const HomeGlobalDataArray = [
    {
        title: "The Global Motorcycling Opportunity",
        img: require("../Assert/Home/icon-1.png"),
        description: "Inviting VC’s / PE’s/ Family Offices / UHNI’s and others passionate about motorcycling. Explore this global opportunity as we build the first of its kind community led multi-platform network model; powered by a robust technology framework we are sitting on the cusp of a big leap in growing the motorcycling community. Connect with us to set up a meeting now."
    },
    {
        title: "Partner with us",
        img: require("../Assert/Home/icon-2.png"),
        description: "We believe in partnerships that help our commune. Connect with us to explore different possibilities in creating and curating rides, experiences, events, meet-ups, knowledge camps, training sessions or simply chilling out with fellow bikers. Use our trademarked brand IP’s to deliver superior experience to the commune. Call our brand partnership team today to get the ride started."
    },
    {
        title: "Commune Stories",
        img: require("../Assert/Home/icon-3.png"),
        description: "Through our Den Talk we have tried to bring together the best of biker experiences from around the world. This is a platform where bikers can showcase their achievements, records, accolades, rides, knowledge and experience with fellow riders in the commune. Reach out to us to help reach your stories across the commune."
    },
    {
        title: "Motorcycle Tourism",
        img: require("../Assert/Home/icon-4.png"),
        description: "Motorcycles are the new Search Engines in helping grow the tourism map; we are setting new benchmarks in opening up the opportunities presented by Motorcycle tourism. Tie up with us to promote your Country/ State / Destination. Let us customise the right mix of rides to help grow tourism."
    },
    {
        title: "Ride on the Tech platform",
        img: require("../Assert/Home/icon-5.png"),
        description: "We are powering the Commune with the latest in cutting edge technology so that our riders can search, discover, meet and build a strong network of bikers, riding experiences and more. Contact us to know more."
    },
]
export const HomeDataArray = [
    {
        title: "Events",
        img: require("../Assert/Home/1.jpg"),
    },
    {
        title: "Blogs",
        img: require("../Assert/Home/blogs.jpg"),
    },
    {
        title: "Videos",
        img: require("../Assert/Home/2.jpg"),
    },
    {
        title: "Gallery",
        img: require("../Assert/Home/gallery.jpg"),
    },
    {
        title: "Press Releases",
        img: require("../Assert/Home/4.jpg"),
    },
    {
        title: "Big Biking App",
        img: require("../Assert/Home/New-Project-8.jpg"),
    },
]
export const OurPartnersArray = [
    {
        img: require("../Assert/Home/OurPartner/5-hardley-davidson.jpg"),
    },
    {
        img: require("../Assert/Home/OurPartner/7-ducati.jpg"),
    },
    {
        img: require("../Assert/Home/OurPartner/3-truph.jpg"),
    },
    {
        img: require("../Assert/Home/OurPartner/9-benelli.jpg"),
    },
    {
        img: require("../Assert/Home/OurPartner/6-re.jpg"),
    },
    {
        img: require("../Assert/Home/OurPartner/1-tvs-apache.jpg"),
    },
    {
        img: require("../Assert/Home/OurPartner/4-indian-oil.jpg"),
    },
    {
        img: require("../Assert/Home/OurPartner/8-amco.jpg"),
    },
]
export const GovernmentBoardsArray = [
    {
        img: require("../Assert/Home/OurPartner/1-tourism-malaysia.jpg"),
    },
    {
        img: require("../Assert/Home/OurPartner/3-incredible-india.jpg"),
    },
    {
        img: require("../Assert/Home/OurPartner/2-enchanting-tamilnadu.jpg"),
    },
]