export const PressReleasesDataArray = [
    {
        title: "Pre Event - Business Wire Clips",
        img: require("../Assert/PressReleases/1-pre-event.jpg"),
    },
    {
        title: "BBC- Post Event Coverage",
        img: require("../Assert/PressReleases/2-bbc.jpg"),
    },
    {
        title: "Pre Event Stories and Listings",
        img: require("../Assert/PressReleases/3-pre-event-stories.jpg"),
    },
    {
        title: "World Moto Yoga Day-Bangalore",
        img: require("../Assert/PressReleases/4-world-moto-yoga-day.jpg"),
    },
 
    {
        title: "WOW RIDES SPICE ROUTE ODYSSEY",
        img: require("../Assert/PressReleases/5-wow-rides.jpg"),
    },
    {
        title: "Magical Malaysia Motorcycle Ride",
        img: require("../Assert/PressReleases/IMG_9426-scaled.jpg"),
    },
    {
        title: "WOW RIDES SPICE ROUTE ODYSSEY",
        img: require("../Assert/PressReleases/my-mechanic-my-friend.jpg"),
    },
    {
        title: "KARGIL VIJAY DIWAS",
        img: require("../Assert/PressReleases/8-kargil-vijay-diwas.jpg"),
    },
    {
        title: "PRE/POST WORLD MOTO YOGA DAY",
        img: require("../Assert/PressReleases/9-pre-post-coverage.jpg"),
    },
    {
        title: "Ride in India",
        img: require("../Assert/PressReleases/10-ride-in-india.jpg"),
    },
    {
        title: "WORLD MOTO YOGA DAY",
        img: require("../Assert/PressReleases/11-world-moto.jpg"),
    },
]