import React, { useContext, useState } from 'react';
import { BottomContent } from '../App';
import Banner from "../Assert/Home/home-banner.jpg"
import { useNavigate } from 'react-router-dom';
import { BigBikingCommune, GovernmentBoardsArray, HomeDataArray, HomeGlobalDataArray, OurPartnersArray } from '../utils/HomeData';

function Home() {
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedIndex1, setSelectedIndex1] = useState(-1);
    const onSelectIndex = (index) => {
        if (selectedIndex == index) {
            setSelectedIndex(-1)
        }
        else {
            setSelectedIndex(index)
        }
    }
    const OurPartnerArray = [
        "Our Partners",
        "Clubs in the Commune",
        "Supported by Government Boards"
    ]
    const UseBottomContext = useContext(BottomContent);
    const navigate = useNavigate();
    return (
        <div ref={UseBottomContext} className='flex flex-col'>
            <div style={{ backgroundImage: `url(${Banner})` }} className='bg-no-repeat bg-cover flex flex-col space-y-5 items-center justify-center bg-center h-[87vh]'>
            </div>
            <div className='bg-[#F1F1F1] overflow-hidden py-10'>
                <div className='max-w-[1400px] mx-auto p-3'>
                    <div className='grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 gap-8'>
                        {HomeGlobalDataArray.map((item, index) =>
                            <div onPointerEnter={()=>setSelectedIndex1(index)} className='flex flex-col gap-5 relative overflow-hidden'>
                                <div key={index} className='col-span-1 flex-1 border-b-4 border-b-[#e81938] flex flex-col gap-5 border border-[#8b9094] p-[60px_30px]'>
                                    <img className='w-full h-[100px] object-contain float-left' src={item.img} />
                                    <div className='text-[#232e35] text-2xl float-left font-semibold uppercase'>{item.title}</div>
                                </div>
                                {selectedIndex1 == index &&
                                    <div key={index} className='Team absolute col-span-1 overflow-auto h-[350px] bg-[#e81938] flex flex-col gap-5 border border-[#e81938] p-[10px_20px]'>
                                        <div className='text-white text-[21px] float-left font-semibold uppercase'>{item.title}</div>
                                        <p className='text-white text-sm text-justify'>{item.description}</p>
                                    </div>}
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='py-16 overflow-hidden'>
                <div className='max-w-[1400px] mx-auto p-3 flex flex-col gap-4'>
                    <h3  className='text-[21px]'>WELCOME TO          </h3>
                    {/* <img className='w-10' src={require("../Assert/Home/title.png")}/> */}
                    <h3  className='text-[#e81938]' >Big Biking Commune</h3>
                    {BigBikingCommune.map((item, index) =>
                        <section key={index} className='grid lg:grid-cols-2 grid-cols-1 gap-5'>
                            <div className='col-span-1 flex flex-col items-start space-y-5'>
                                <p >{item.description1}</p>
                                <p >{item.description2}</p>
                                <p >{item.description3}</p>
                                <button onClick={() => navigate("/About")} className='text-[#e81938] border-2 border-[#e81938] p-[6px_24px] font-bold float-left'>READ MORE</button>
                            </div>
                            <div className='col-span-1 flex flex-col overflow-hidden h-[380px]'>
                                <img  className='w-full h-full object-cover hover:scale-105 duration-300 rounded-sm' src={item.img} />
                            </div>
                        </section>)}
                </div>
            </div>
            <div className='bg-[#F1F1F1] overflow-hidden py-10'>
                <div className='max-w-[1400px] mx-auto p-3 flex flex-col gap-5'>
                    <h3  className='text-[21px]'>Follow our story
                    </h3>
                    <h3  >Media Centre                    </h3>
                    <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6'>
                        {HomeDataArray.map((item, index) =>
                            <div key={index} className='col-span-1 overflow-hidden border-b-[6px] border-b-[#e81938] relative'>
                                <img className='w-full h-full object-cover transform hover:scale-100' src={item.img} />
                                <div className='text-white text-2xl font-bold opacity-[1px] visible absolute left-[20px]  bottom-[20px]'>{item.title}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className='bg-[#ECECEC] overflow-hidden py-10'>
                <div className='max-w-[1400px] mx-auto p-3 flex flex-col gap-5'>
                    <h3  className='text-[21px]'>ride with us</h3>
                    <h3  >Join the Commune</h3>
                    <div className='flex flex-row items-center justify-center gap-5 border-b-2 border-b-black'>
                        {OurPartnerArray.map((item, index) =>
                            <h3 onClick={() => setSelectedIndex(index)} className={`${selectedIndex == index ? "border-b-4  border-b-[#e81938]" : "border-b-4 border-b-[#ECECEC]"} text-lg cursor-pointer`}>{item}</h3>
                        )}
                    </div>
                    <p>We thank our partners / sponsors who have supported our past events / activities. Contact us to curate new experiences / rides for the commune.
                    </p>
                    {selectedIndex == 0 ?
                        <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6'>
                            {OurPartnersArray.map((item, index) =>
                                <div key={index} className='bg-white shadow-lg rounded-md'>
                                    <img className='w-full h-full object-contain rounded-md' src={item.img} />
                                </div>
                            )}
                        </div> :
                        selectedIndex == 1 ?
                            <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-6'>
                                {OurPartnersArray.map((item, index) =>
                                    <div key={index} className='bg-white shadow-lg rounded-md'>
                                        <img className='w-full h-full object-contain rounded-md' src={item.img} />
                                    </div>
                                )}
                            </div> :
                            selectedIndex == 2 ?
                                <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6 place-content-center'>
                                    {GovernmentBoardsArray.map((item, index) =>
                                        <div key={index} className='bg-white shadow-lg rounded-md p-5'>
                                            <img className='w-full h-full object-contain rounded-md' src={item.img} />
                                        </div>
                                    )}
                                </div> : null}
                </div>
            </div>

        </div>
    )
}

export default Home;