


export const SponsorsDataArray = [
    {
      title: "ABSOLUT",
      img: require("../Assert/Partner/ABSOLUT.jpg"),
    },
    {
      title: "AGUSTA MV",
      img: require("../Assert/Partner/AGUSTA MV.jpg"),
    },
    {
      title: "AMCO",
      img: require("../Assert/Partner/AMCO.jpg"),
    },
    {
      title: "APACHE SERIES RTR",
      img: require("../Assert/Partner/TVS MOTORS.jpg"),
    },
    {
      title: "APOLLO TYRES",
      img: require("../Assert/Partner/APOLLO TYRES.jpg"),
    },
    {
      title: "AVANTURA CHOPPERS",
      img: require("../Assert/Partner/AVANTURA CHOPPERS.jpg"),
    },
    {
      title: "AWARE INDIA",
      img: require("../Assert/Partner/AWARE INDIA.jpg"),
    },
    {
      title: "AXIOM",
      img: require("../Assert/Partner/AXIOM.jpg"),
    },
    {
      title: "AXO",
      img: require("../Assert/Partner/AXO.jpg"),
    },
    {
      title: "CARBONADO",
      img: require("../Assert/Partner/CARBONADO.jpg"),
    },
    {
      title: "CELLULAR LINE",
      img: require("../Assert/Partner/CELLULAR LINE.jpg"),
    },
    {
      title: "CHARIOT BEACH RESORT MAHABALIPURAM",
      img: require("../Assert/Partner/CHARIOT BEACH RESORT MAHABALIPURAM.jpg"),
    },
    {
      title: "CHRIS CROSS",
      img: require("../Assert/Partner/CHRIS CROSS.jpg"),
    },
    {
      title: "COCA COLA",
      img: require("../Assert/Partner/COCA COLA.jpg"),
    },
    {
      title: "DUCATI",
      img: require("../Assert/Partner/DUCATI.jpg"),
    },
    {
      title: "F.B MONDIAL",
      img: require("../Assert/Partner/F.B MONDIAL.jpg"),
    },
    {
      title: "FALCO MOTORCYCLE BOOTS",
      img: require("../Assert/Partner/FALCO MOTORCYCLE BOOTS.jpg"),
    },
    {
      title: "FEVER 91.9 FM",
      img: require("../Assert/Partner/FEVER 91.9 FM.jpg"),
    },
    {
      title: "FURYGAN",
      img: require("../Assert/Partner/FURYGAN.jpg"),
    },
    {
      title: "GLOBAL HOSPITALS",
      img: require("../Assert/Partner/GLOBAL HOSPITALS.jpg"),
    },
    {
      title: "HARLEY DAVIDSON",
      img: require("../Assert/Partner/HARLEY DAVIDSON.jpg"),
    },
    {
      title: "HERO",
      img: require("../Assert/Partner/HERO.jpg"),
    },
    {
      title: "HUMMER",
      img: require("../Assert/Partner/HUMMER.jpg"),
    },
    {
      title: "HYOSUNG",
      img: require("../Assert/Partner/HYOSUNG.jpg"),
    },
    {
      title: "INDIAN MOTORCYCLE",
      img: require("../Assert/Partner/INDIAN MOTORCYCLE.jpg"),
    },
    {
      title: "INDIAN OIL",
      img: require("../Assert/Partner/INDIAN OIL.jpg"),
    },
    {
      title: "JAMESON",
      img: require("../Assert/Partner/JAMESON.jpg"),
    },
    {
      title: "JAYEM AUTOMATIVES LTD",
      img: require("../Assert/Partner/JAYEM AUTOMATIVES LTD.jpg"),
    },
    {
      title: "JEWELULTRA",
      img: require("../Assert/Partner/JEWELULTRA.jpg"),
    },
    {
      title: "JMB",
      img: require("../Assert/Partner/JMB.jpg"),
    },
    {
      title: "KAWASAKI",
      img: require("../Assert/Partner/KAWASAKI.jpg"),
    },
    {
      title: "KINGFISHER",
      img: require("../Assert/Partner/KINGFISHER.jpg"),
    },
    {
      title: "LIQUI MOLY",
      img: require("../Assert/Partner/LIQUI MOLY.jpg"),
    },
    {
      title: "MAISTO",
      img: require("../Assert/Partner/MAISTO.jpg"),
    },
    {
      title: "MONSTER ENERGY DRINK",
      img: require("../Assert/Partner/MONSTER ENERGY DRINK.jpg"),
    },
    {
      title: "MOTOGEAR",
      img: require("../Assert/Partner/MOTOGEAR.jpg"),
    },
    {
      title: "MOTOROYALE",
      img: require("../Assert/Partner/MOTOROYALE.jpg"),
    },
    {
      title: "MOTUL",
      img: require("../Assert/Partner/MOTUL.jpg"),
    },
    {
      title: "NOKIA",
      img: require("../Assert/Partner/NOKIA.jpg"),
    },
    {
      title: "NORTON",
      img: require("../Assert/Partner/NORTON.jpg"),
    },
    {
      title: "POLARIS",
      img: require("../Assert/Partner/POLARIS.jpg"),
    },
    {
      title: "ROYAL BROTHERS",
      img: require("../Assert/Partner/ROYAL BROTHERS.jpg"),
    },
    {
      title: "ROYAL ENFIELD",
      img: require("../Assert/Partner/ROYAL ENFIELD.jpg"),
    },
    {
      title: "RYNOX",
      img: require("../Assert/Partner/RYNOX.jpg"),
    },
    {
      title: "STYLMARTIN",
      img: require("../Assert/Partner/STYLMARTIN.jpg"),
    },
    {
      title: "SWM",
      img: require("../Assert/Partner/SWM.jpg"),
    },
    {
      title: "TAICHI",
      img: require("../Assert/Partner/TAICHI.jpg"),
    },
    {
      title: "THROTTLE",
      img: require("../Assert/Partner/AMCO.jpg"),
    },
    {
      title: "TOURISM SOLUTIONS",
      img: require("../Assert/Partner/TOURISM SOLUTIONS.jpg"),
    },
    {
      title: "TURMS",
      img: require("../Assert/Partner/TURMS.jpg"),
    },
    {
      title: "TVS MOTORS",
      img: require("../Assert/Partner/TVS MOTORS.jpg"),
    },
    {
      title: "UCAL FUEL SYSTEMS",
      img: require("../Assert/Partner/UCAL FUEL SYSTEMS.jpg"),
    },
    {
      title: "XBHP",
      img: require("../Assert/Partner/XBHP.jpg"),
    },
    {
      title: "6000 MILES TO ILES OF MAN",
      img: require("../Assert/Partner/6000 MILES TO ILES OF MAN.jpg"),
    },
  ]