import React, { useContext } from 'react'
import { BottomContent } from '../../App';
import BannersComponent from '../../Constant/BannersComponent';
import Banner from "../../Assert/Home/home-banner.jpg"

function CancellationPolicy() {
  const UseBottomContext = useContext(BottomContent);

  return (
    <div>
      <BannersComponent Banner={Banner} name="Cancellation Policy"/>
      <div ref={UseBottomContext} className='py-10'>
        <div className='max-w-[1400px] mx-auto p-3 flex flex-col gap-3'>
          <div className='text-[#3e3e3e] text-xl font-medium'>Cancellation Policy
          </div>
          <p>
            Please note an order can only be canceled within 24 hours of placing the order. Once the order is processed after 24 hours, no cancellation request will be entertained.
          </p>
        </div>
      </div>
    </div>
  )
}

export default CancellationPolicy