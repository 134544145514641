export const HeaderMeneu = [
    {
        name: "HOME",
        route: "/",
    },
    {
        name: "ABOUT US",
        route: "/About",
    },
    {
        name: "CLUBS",
        route: "/clubs",
    },
    {
        name: "PARTNERS",
        route: "Sponsors",
        subName:[
            {
                name:"SPONSORS",
                route:"/Sponsors"
            },
            {
                name:"GOVERNMENT BOARDS",
                route:"/Government"
            },
        ]
    },
    {
        name: "MEDIA",
        route: "PressReleases",
        subName:[
            {
                name:"PRESS RELEASES",
                route:"/PressReleases"
            },
            {
                name:"BLOG",
                route:"/Blog"
            },
        ]
    },
    {
        name: "GALLERY",
        route: "/Gallery",
    },
    {
        name: "CONTACT",
        route: "/Contact",
    },
]