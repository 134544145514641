import React, { useContext } from 'react'
import { BottomContent } from '../../App';
import BannersComponent from '../../Constant/BannersComponent';
import Banner from "../../Assert/Home/home-banner.jpg"

function RefundPolicy() {
  const UseBottomContext = useContext(BottomContent);

  return (
    <div>
      <BannersComponent Banner={Banner} name="Refund and Cancellation"/>
      <div ref={UseBottomContext} className='py-10'>
        <div className='max-w-[1400px] mx-auto p-3 flex flex-col gap-3'>
          <div className='text-[#3e3e3e] text-xl font-medium'>Refund and Cancellation </div>
          <p>Due to service provided in nature “NO REFUND”,“NO CANCELLATION” will be entertained once the Payment has been made.
          </p>
        </div>
      </div>
    </div>
  )
}

export default RefundPolicy