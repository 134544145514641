import React, { useContext, useState } from 'react';
import { BottomContent } from '../App';
import Banner from "../Assert/Blogs/Blog-banner.jpg"
import { BlogsDataArray } from '../utils/BlogsData';
import BannersComponent from '../Constant/BannersComponent';

function Blogs() {
    const UseBottomContext = useContext(BottomContent);
    const [first, setfirst] = useState("")
    return (
        <div ref={UseBottomContext} className='flex flex-col'>
                      <BannersComponent Banner={Banner}/>

            <div className='bg-[#F1F1F1] py-10'>
                <div className='max-w-[1400px] mx-auto p-3'>
                    {first == "" ?
                        <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6'>
                            {BlogsDataArray.map((item, index) =>
                                <div key={index} className='col-span-1 flex flex-col gap-5 float-left'>
                                    <div className='overflow-hidden'>
                                        <img className='w-full h-full object-cover min-h-32 transition-all hover:scale-100' src={item.img} />
                                    </div>
                                    <hr className='border-b-[1px] border-b-[#ccc] ' />
                                    <div className='text-[#3e3e3e] text-xl font-medium'>{item.title}</div>
                                    <p>{item.description}</p>
                                    <div onClick={() => setfirst(item.route)} className='text-[#e81938] font-bold text-sm hover:underline cursor-pointer'> READ MORE</div>
                                </div>
                            )}
                        </div>
                        : first == "Freedom" ?
                            <div className='grid lg:grid-cols-3 grid-cols-1 gap-6'>
                                <div className='lg:col-span-2 col-span-1 flex flex-col gap-5'>
                                    <h3 className='text-[#e81938] text-start'>Freedom on Wheels</h3>
                                    <p>One would not be lying if he says that the wheels of his motorcycle which used to travel through the roads of cities, villages, mountains, and forests have been inactive for the past two years. As the whole world shut due to the pandemic, this was probably the first time we witnessed roads being locked, state borders being barricaded and travel being restricted to such a large scale. The beginning of the nationwide lockdown did give us doubts if road trips would be possible in the near future and slowly that became a reality as we had to take a long break from motorcycling for almost two years. Life took a new turn where we were forced to live, work and explore within the confined space of our homes. Staying indoors has frustrated all of us irrespective of what our work or lifestyle is and motorcyclists are no exception. Traveling on motorcycles is one of the greatest joys for a rideras it is associated with freedom, exploration, brotherhood, friendships and much more. Riders have sure missed throttling; vrooming their way across highways and the hairpin bends on hill stations. The sight of the changing landscapes such as the majestic mountains, range of valleys, flowing rivers and the dense forests as onecruises on a motorcycle is something every traveller missed during the lockdown.</p>
                                    <p>Everyone would have countless stories to share- experiences, engagement with locals, challenging moments and rides. Right from the highway motels, tea stalls and mechanic shops, a rider comes in contact with these people through the journey. In fact, it is astonishing how one can form such memorable relationships with people we never knew within a short span. It is the bikers and road travellers who give hope for such people to earn their everyday bread. The pandemic did not spare this section of the society, as tourism was one of the worst affected industries globally.</p>
                                    <p>Local businessmen, artisans, shopkeepers, hoteliers, vendors, and local tour guides that bikers meet during their journey form the unorganized sector of the tourism industry. The crisis they underwent reflected the impact motorcyclists have on the businesses and daily earnings of these people. With gradual return of normalcy, travel restrictions are being relaxed with an emphasis on safety precautions and people getting vaccinated. Borders are opening up, encouraging travellers to take their break which they have missed over two years. Unlike airplane, bus or a train, bikes are the safest way for travel since this would involve just two people and they do not come in contact with a larger crowd during the travel time.</p>
                                    <p>Solo rides on a deserted road should not pose much threat to anyone and if it is in groups, a small team of four to five people should be ideal for being safe. The only hiccup would be to assess risk factors such as the rate of community spread in a certain locality and checking the current health mandates with the local public health authorities. Maintaining social distancing and the use of face masks would further strengthen the safety. With better discipline in life and a better health, let us together overcome the darker days, and help boost the tourism industry thereby keeping the passion alive for traveling. Get ready for Freedom 2.0!
                                    </p>
                                </div>
                                <div className='col-span-1 flex flex-col gap-5 mt-16'>
                                    <h3 className='text-[#3e3e3e] text-start'>Recent Blogs</h3>
                                    <div className='flex flex-row gap-3'>
                                        <img className='w-28 h-20' src={require("../Assert/Blogs/motorcycle-scaled.jpg")} />
                                        <div onClick={() => setfirst("Motorcycles")} className='text-[#3e3e3e] text-xl font-medium rounded-sm cursor-pointer'>Motorcycles- the new travel search engine.</div>
                                    </div>
                                    <hr className='border-b-[1px] border-b-[#ccc] ' />

                                    <div className='flex flex-row gap-3'>
                                        <img className='w-28 h-20' src={require("../Assert/Blogs/motorcycle-scaled.jpg")} />
                                        <div onClick={() => setfirst("Thank")} className='text-[#3e3e3e] text-xl font-medium rounded-sm cursor-pointer'>Thank you Commune!</div>
                                    </div>
                                    <hr className='border-b-[1px] border-b-[#ccc] ' />
                                </div>
                            </div> : first == "Motorcycles" ?
                                <div className='grid lg:grid-cols-3 grid-cols-1 gap-6'>
                                    <div className='lg:col-span-2 col-span-1 flex flex-col gap-5'>
                                        <h3 className='text-[#e81938] text-start'>Motorcycles - the new travel search engine</h3>
                                        <p>Most travelers' itinerary consists of visiting the top tourist spots like temples, museums, and monuments or popular trekking routes. It hardly lets you discover new routes or unexplored locations. A journey in a car, a stay in a 5 five star hotel, and eating in a fancy restaurant robs us of our thirst to explore the real culture of a location- the taste of the local cuisine, the dialects of the strange native language, the warm welcoming homes of the locals, the narrow streets of the busy towns and villages and few hidden spots in a place that can leave you mesmerized.</p>
                                        <p>A quick fix to this i.e, to be a traveler and not a tourist can be done by traveling on a motorcycle. Motorcycling never got its due credit for redefining the path of travel exploration. It has carved the path for finding numerous routes leading to magical spots that one could never have access to if they followed a digital map or search engine. Motorcycles push you to a direct experiential and immersive way to explore new locations. It gives you the freedom to take a quick detour through a new passage that might lead you to raging rivers, sandy desserts, pristine beaches, and mighty mountains. The point is you’ll never know what lies ahead.</p>
                                        <p>Another aspect is that a motorcycle is adored by many, irrespective of people’s economic class. So we could randomly pull up to a remote village and people would check out our motorcycle and gear. It simply gives us the license to engage better with people and this can range from chai waales to local landlords. There are also times when we get to take a pillion (young children from villages) to escort us to some of the interior locations which they would have otherwised traveled by foot. The joy on their face as the wind hits their face as we accelerate through the narrow roads, is something we would cherish as a biker.Little pleasures like these will be missed, when we go by a set itenary accompanied by a tourist guide.</p>
                                        <p>Beginners might feel hesitant to travel to unexplored locations, but this is how one can start. Riding in a group is one of the best ways. There will always be a helping hand in case something goes wrong and one need not worry about losing direction. Having a lead rider who has enough experience traveling the roads or joining a group of bikers should help one get accustomed to the techniques of motorcycle riding until one gains the confidence to set out on a solo trip.</p>
                                        <p>Staying connected with the biking community also opens doors for meeting new riders and strengthening the bond with existing fellow riders which can go beyond motorcycling. Most riders prefer this over solo riding or a ride with a digital plan.</p>
                                        <p>While the recent pandemic has shut doors for the biking community to be active, it will not be long before these two-wheeled machineries get back on the track to discover unexplored paths and put them on the maps for the future riders.
                                        </p>
                                    </div>
                                    <div className='col-span-1 flex flex-col gap-5 mt-16'>
                                        <h3 className='text-[#3e3e3e] text-start'>Recent Blogs</h3>
                                        <div className='flex flex-row gap-3'>
                                            <img className='w-28 h-20' src={require("../Assert/Blogs/freedom-wheel.jpg")} />
                                            <div onClick={() => setfirst("Freedom")} className='text-[#3e3e3e] text-xl font-medium cursor-pointer rounded-sm'>Freedom on Wheels 2.0</div>
                                        </div>
                                        <hr className='border-b-[1px] border-b-[#ccc] ' />
                                        <div className='flex flex-row gap-3'>
                                            <img className='w-28 h-20' src={require("../Assert/Blogs/motorcycle-scaled.jpg")} />
                                            <div onClick={() => setfirst("Thank")} className='text-[#3e3e3e] text-xl font-medium rounded-sm cursor-pointer'>Thank you Commune!</div>
                                        </div>
                                        <hr className='border-b-[1px] border-b-[#ccc] ' />
                                    </div>
                                </div> : first == "Thank" ?
                                    <div className='grid lg:grid-cols-3 grid-cols-1 gap-6'>
                                        <div className='lg:col-span-2 col-span-1 flex flex-col gap-5'>
                                            <h3 className='text-[#e81938] text-start'>Thank you Commune
                                            </h3>
                                            <p>What started as an idea 3 years ago to bring the biking fraternity under a common experiential and bonding platform is a reality today. The Big Biking Commune has journeyed from just a possibility to becoming one of the largest networking platform for bikers to come together; make new biker friends, share riding experience, learn from veteran bikers, immerse in the ambience. This would not have been possible without any of our motorcycling fraternity who welcomed this with an open heart. Thank you will be a small word to fully appreciate the love and affection showered on us and the loads of help and support we have received in putting this together. We were overwhelmed with the 4000 plus bikers attending our first flagship event at Mahabalipuram in Feb’19. Cutting across bikes, clubs, riders, brands the entire Commune gathered as ‘One’ for 3 days of festivities, bonding, entertainment, learning and sharing experiences.Bikers like MaralYazarloo, VeenaShetty, Aravind KP to name a few, shared their inspiring stories.</p>
                                            <p>Amidst the buzz stood some mighty, majestic custom and vintage collections of bikes.Yamaha RD 350one of the most popular motorcycles, with the Skin Deep Custom and Custom like an ant was an eye catcher, while the Interceptor 750 of Royal Enfield and Avantura Choppers stood mighty among others.Going with the thought ‘Old is Gold’ The Jawa also showcased a custom bobber model called the Jawa Perak.</p>
                                            <p>Professional stunt riding teams, TVS apache racing team with their rocking neon wheels and the legendary Ghost Ryderz with their popping red wheels took the audience’s breath away with their show stopping, gravity defying stunt riding skills. The audiences were on their feet throughout the show and were thrilled to witness such greatness.</p>
                                            <p>Lastly, what good is entertainment without music? Big Biking Commune grooved to the music spell casted by some of the top music bands in the country including Thaikkudam Bridge, Bangalore based fusion band ‘Pineapple Express’, Swahaa, the Unknowns by Steve, traditional parai performers ‘MaiyamKalaikuzhu’, and Kanvas. The scintillating performance by the band brought life to the night as the audience’s energy radiated with their electrifying celebratory mood.</p>
                                            <p>Apart from just bringing the festive events, Big Biking Commune also looked at curating initiatives and ride experiences for bikers. One of the most unique and globally first initiatives was the World Motoyoga Day. The World MotoYoga day was formed marking World Motorcycling Day and the International Day of Yoga, which coincides on 21st June every year.  To celebrate Yoga, Fitness and the spirit of motorcycling , World Moto Yoga Day was formed as the ideal platform to promote health and wellness amongst the biker community. We have successfully completed 3 editions of World Motoyoga Day, first being an on ground event in Chennai and Bengaluru where we gathered bikers from the city to indulge in a session of Yoga taught by experts from Isha Yoga Foundation. </p>
                                            <p>The 2nd and 3rd edition saw virtual live sessions by Yoga expert YogabandhuPrashanthfrom Ojas Yoga Academy and Biker SameeraDahiya. As part of the initiative, Big Biking Commune also launched the 'Yoga for Bikers' booklet, which has specially curated asanas for bikers. In an attempt to bring in international bikers to India and to take Indian bikers global , we kick started WOW Rides- an experiential ride. The journey is peppered with biker meet-ups, sharing experiences and making new biking buddies. It is about celebrating a global biking culture. In 2019 the first edition of WOW Rides - Spice Route Odyssey, was organised by Big Biking Commune. A team of 5 avid, global motorcycle riders - Declan McEvoy, KarolisMieliauskas, Deepak Kamath, VeenaShetty, MandeepMerwah and SimasSuminskas - travelled along the west and southern spice and coastal regions of India for 10 days. </p>
                                            <p>The team travelled through Mangalore, Bangalore, Mysore, Kochi, Kanyakumari, Madurai, Trichy, Pondicherry and finally reached Chennai. The entire route plan was interwoven with pit stops for photo opportunities at various locations along with meet and greet sessions where the riders shared their learnings and imparted road safety and riding tips, whilst encouraging more Indian bikers to step out and try new riding routes.</p>
                                            <p>Filter Kaapi ride, as the name suggests, is to bring alive the South Indian tradition of strong filter coffee culture; surrounded by spices, plantations and hilly terrain, South India has a lot to offer bikers who can explore the tastes and aroma of coffee and connect with the people. Alongside, it was a networking opportunity for the bikers to connect with the local biker clubs and exchange ideas on biking culture. The plantation stays was an experiential ride for bikers to explore the lifestyle and culture in each region.</p>
                                            <p>Yet another platform to connect global bikers with our commune is the "Den series" by Big Biking Commune. A platform where riders across the globe share about their experiences with motorcycling which will eventually inspire the fellow riders in our commune. The series had bikers from across the world talk about their journey in motorcycling, achievements, challenges faced, motorcycling scenario in their country and their take on the Indian Motorcycling. We have featured bikers from Europe, America, Australia, South East Asia and India on our social media platforms.</p>
                                            <p>All along this journey, what has stayed constant is the love and affection showered on us by the biking fraternity and we hope to live upto their expectations in making this a truly global motorcycling Commune. As largest aggregator platform, we believe the biker community will grow stronger and larger, lifting the spirit of bikerhood where we would commune as one!
                                            </p>

                                        </div>
                                        <div className='col-span-1 flex flex-col gap-5 mt-16'>
                                            <h3 className='text-[#3e3e3e] text-start'>Recent Blogs</h3>
                                            <div className='flex flex-row gap-3'>
                                                <img className='w-28 h-20' src={require("../Assert/Blogs/freedom-wheel.jpg")} />
                                                <div onClick={() => setfirst("Freedom")} className='text-[#3e3e3e] text-xl font-medium cursor-pointer rounded-sm'>Freedom on Wheels 2.0</div>
                                            </div>
                                            <hr className='border-b-[1px] border-b-[#ccc] ' />
                                            <div className='flex flex-row gap-3'>
                                                <img className='w-28 h-20' src={require("../Assert/Blogs/freedom-wheel.jpg")} />
                                                <div onClick={() => setfirst("Motorcycles")} className='text-[#3e3e3e] text-xl font-medium cursor-pointer rounded-sm'>Freedom on Wheels 2.0</div>
                                            </div>
                                            <hr className='border-b-[1px] border-b-[#ccc] ' />
                                        </div>
                                    </div> : null}

                </div>
            </div>
        </div >
    )
}


export default Blogs;