import React from 'react'
import { motion } from "framer-motion";
import fadeIn from '../variants';
import { MdOutlineSearch } from 'react-icons/md';
import { AiOutlineClose } from 'react-icons/ai';
function SearchPage(props) {
    return (
        <div>
            {props.showSearch ?
                <div initial={{ y: '-100%' }} exit={{ y: '-100%' }} whileInView={{ y: 0 }} transition={{ duration: 0.7 }} className={`flex flex-col z-50 items-center justify-center bg-white shadow-md w-full top-0 absolute left-0 h-[250px] p-4`}>
                    <div className='xl:max-w-[1400px] mx-auto p-3 w-full'>
                        <div className='flex flex-row items-center justify-between w-full border-b-[1px] space-x-3  border-[#999]'>
                            <MdOutlineSearch className='text-2xl text-[#999]' />
                            <input placeholder='Type Your Search' className='border-none bg-white w-full text-[#696969] text-base float-left outline-none' />
                            <AiOutlineClose onClick={() => props.setShowSearch(false)} className='text-2xl text-[#999] cursor-pointer' />
                        </div>
                    </div>
                </div> : null}
        </div>
    )
}

export default SearchPage;





