import React, { useContext } from 'react';
import { BottomContent } from '../App';
import Banner from "../Assert/PressReleases/Press-banner.jpg"
import { PressReleasesDataArray } from '../utils/PressReleasesData';
import BannersComponent from '../Constant/BannersComponent';

function PressReleases() {

    const UseBottomContext = useContext(BottomContent);
    return (
        <div ref={UseBottomContext} className='flex flex-col'>
                       <BannersComponent Banner={Banner}/>

            <div className='bg-[#F1F1F1] overflow-hidden py-10'>
                <div className='max-w-[1400px] mx-auto p-3'>
                    <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6'>
                        {PressReleasesDataArray.map((item, index) =>
                            <div key={index} className='col-span-1 flex flex-col gap-3'>
                                <img className='w-full h-full object-cover transform hover:scale-100' src={item.img} />
                                <div className='text-[#3e3e3e] text-xl font-medium text-center visible rounded-sm'>{item.title}</div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}


export default PressReleases;