import React from 'react';

function ClientCard(props) {
  return (
    <div className='bg-white shadow-lg rounded-md border shadow-[#00000026] text-lg font-normal flex flex-col gap-5 items-center p-5 h-[230px]'>
      <img className='w-full h-[70%] object-contain rounded-md' src={props.img} />
      <div className='text-center'>{props.title}</div>
    </div>
  )
}

export { ClientCard };
