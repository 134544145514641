import fadeIn from '../variants';


export const BusinessGoals= [
    {
        img: require("../Assert/About/connect-motorcycle-clubs.jpg"),
        title: "Motorcycle Clubs",
        description: "Register your club with the Commune. Get your rides, events, activities featured . Use our platform to reach out to the larger motorcycling fraternity across the world and share your experiences.",
        variants:fadeIn("top", 0.3)
    },
    {
        img: require("../Assert/About/connect-brand-initiatived-rides.jpg"),
        title: "Brand Rides & Activities",
        description: "Use our platform to network and connect to the larger motorcycle family. Showcase your rides, events and hold workshops, expert talk shows. Feature your star riders and share new launches and announcements with the biking fraternity.",
        variants:fadeIn("top", 0.4)
    },
    {
        img: require("../Assert/About/connect-motorcycle-tour-operators.jpg"),
        title: "Bike Rentals | Motorcycle Tours",
        description: "Are you operating a motorcycle tour or bike rental services? This is the right platform to connect with the bikers and create new riding experiences. Talk to us to know more.",
        variants:fadeIn("top", 0.5)
    },
    {
        img: require("../Assert/About/connect-big-den-talks.jpg"),
        title: "Vloggers | Influencers",
        description: "Want to create a fan following? Or want to share your expertise and knowledge or you want to connect with the biker fraternity or you want to learn how to become an expert and create a positive influence amongst our biker friends. More possibilities open up with us. Join the Commune and grow the circle of influence.",
        variants:fadeIn("bottom", 0.3)
    },
    {
        img: require("../Assert/About/motorcycles-group-parking-city-street-summer-1-scaled.jpg"),
        title: "Moto-Workshops",
        description:"The best way to onboard new members into the commune is to impart training and the right riding etiquette. Reach out to us help us help budding bikers. Join hands with us if you are into training, workshop, mechanic boot camps, dirt track training or any academy in motor sports. There is a lot we can do together.",
        variants:fadeIn("bottom", 0.4)
    },
    {
        img: require("../Assert/About/young-woman-posing-motorcycle-scaled.jpg"),
        title: "Big Den Talks",
        description: "We will feature several achievers who inspire our commune. This is a platform for them to talk to the commune members and showcase their achievement, share their experience or wow us with their craftsmanship or coach and mentor us.",
        variants:fadeIn("bottom", 0.5)
    },
  
]

export const WhatWeDoArray= [
    {
        img: require("../Assert/About/lo-BBC.jpg"),
        title: "Annual Biker Meetup",
        description: "Our annual festival celebrating the biker in all of us; spread across 2 days of exhilarating and adrenaline pumping events, entertainment, shows, parades and more. Every year it will be held at a new destination in India and across different countries as we reach out to bikers across the world to celebrate the belongingness as part of a single commune.",
        variants:fadeIn("top", 0.3)
    },
    {
        img: require("../Assert/About/lo-WR.jpg"),
        title: "Biking Beyond Borders",
        description: "Our WOW rides (World on Wheels) are designed to help discover destinations on many endless road journeys; the journey is peppered with biker meet-ups, sharing experiences and making new biking buddies. It is about celebrating a global biking culture.",
        variants:fadeIn("top", 0.4)
    },
    {
        img: require("../Assert/About/lo-MTS.jpg"),
        title: "M.I.C.E on Motorcycles",
        description: "It is our way of promoting tourism destination on motorcycles. A new platform that will unit all stakeholders to make motorcycles the New Search Engines. Let the journey uncover more destination while we make motorcycles a part of the mainstream tourism.",
        variants:fadeIn("top", 0.5)
    },
    {
        img: require("../Assert/About/lo-WMYD.jpg"),
        title: "Yoga for Bikers",
        description: "Want to create a fan following? Or want to share your expertise and knowledge or you want to connect with the biker fraternity or you want to learn how to become an expert and create a positive influence amongst our biker friends. More possibilities open up with us. Join the Commune and grow the circle of influence.",
        variants:fadeIn("bottom", 0.3)
    },
    {
        img: require("../Assert/About/lo-MG.jpg"),
        title: "Shoot | Edit | Share | Cherish",
        description:"A picture can speak a 1000 words, a small video can capture your entire ride. Our Motography platform will bring in the experts in photography, videography, drone technology and the best in editing a picture-perfect ride. Workshops, tutorials, seminars, bootcamps, coaching classes, technical sessions and more to keep the buzz going.",
        variants:fadeIn("bottom", 0.4)
    },
    {
        img: require("../Assert/About/lo-FKR.jpg"),
        title: "Thematic Plantation Rides",
        description: "Our Filter Kaapi Ride is designed to marry coffee culture with motorcycling tours. Explore thematically curated coffee plantation tours. From the plantation to your cup, the journey will be exciting.",
        variants:fadeIn("bottom", 0.5)
    },
    {
        img: require("../Assert/About/lo-MCC.jpg"),
        title: "Making Students Responsible Riders.",
        description: "Catch them young, teach them sensible and responsible riding. Our Campus Commune is designed to imbibe responsibility and freedom while teaching students to organise and channel their passion into a personally fulfilling journey.",
        variants:fadeIn("bottom", 0.3)
    },
    {
        img: require("../Assert/About/lo-CMC.jpg"),
        title: "Employee Engagement",
        description:"A rare coincidence that we all celebrate June 21st as the World Motorcycling Day and the International Yoga Day also happens to be celebrated the world over on June 21st. The stress and strain of long-distance rides that we bikers are used to can be easily managed with Yoga practices. While we started this practice, we would be happy to have our commune bikers embrace the practice and popularise the celebration of a healthy biking life. We will be taking Motorcyclists and Yoga to a new level in the coming days.",
        variants:fadeIn("bottom", 0.4)
    },
    {
        img: require("../Assert/About/lo-FKR.jpg"),
        title: "App & TechnologyComing soon ...",
        description: "The Big Biking Commune will soon be coming out with the complete Mobile app for the biker. Built on the robust and latest technology powered by AI/ML and enabled with API plug-ins and seamlessly integrated with IOT technology, our App is designed to help bikers plan the perfect motorcycle trip. Stay tuned to know more.",
        variants:fadeIn("bottom", 0.5)
    },
  
]