export const BlogsDataArray = [
    {
        title: "Freedom on Wheels 2.0",
        img: require("../Assert/Blogs/freedom-wheel.jpg"),
        description: "One would not be lying if he says that the wheels of his motorcycle which used to travel through the roads of cities, villages, mountains, and forests have been inactive for the past two years. As the whole world shut due to the pandemic, this was probably the first time we witnessed roads being locked, state borders being barricaded and travel being restricted to such a large scale.",
        route: "Freedom"
    },
    {
        title: "Motorcycles- the new travel search engine.",
        img: require("../Assert/Blogs/young-asian-male-traveler-photographer-sitting-classic-style-racer-motorbike-holding-camera-scaled.jpg"),
        description: "A journey in a car, a stay in a 5 five star hotel, and eating in a fancy restaurant robs us of our thirst to explore the real culture of a location- the taste of the local cuisine, the dialects of the strange native language, the warm welcoming homes of the locals, the narrow streets of the busy towns and villages and few hidden spots in a place that can leave you mesmerized.",
        route: "Motorcycles"

    },
    {
        title: "Thank you Commune!",
        img: require("../Assert/Blogs/motorcycle-scaled.jpg"),
        description: "What started as an idea 3 years ago to bring the biking fraternity under a common experiential and bonding platform is a reality today. The Big Biking Commune has journeyed from just a possibility to becoming one of the largest networking platform for bikers to come together; make new biker friends, share riding experience, learn from veteran bikers, immerse in the ambience.",
        route: "Thank "

    },
]
export const BlogsData1Array = [
    {
        title: "Freedom on Wheels 2.0",
        img: require("../Assert/Blogs/freedom-wheel.jpg"),
        description: "One would not be lying if he says that the wheels of his motorcycle which used to travel through the roads of cities, villages, mountains, and forests have been inactive for the past two years. As the whole world shut due to the pandemic, this was probably the first time we witnessed roads being locked, state borders being barricaded and travel being restricted to such a large scale.",
        route: "Freedom"
    },
    {
        title: "Motorcycles- the new travel search engine.",
        img: require("../Assert/Blogs/young-asian-male-traveler-photographer-sitting-classic-style-racer-motorbike-holding-camera-scaled.jpg"),
        description: "A journey in a car, a stay in a 5 five star hotel, and eating in a fancy restaurant robs us of our thirst to explore the real culture of a location- the taste of the local cuisine, the dialects of the strange native language, the warm welcoming homes of the locals, the narrow streets of the busy towns and villages and few hidden spots in a place that can leave you mesmerized.",
        route: "Motorcycles"

    },
    {
        title: "Thank you Commune!",
        img: require("../Assert/Blogs/motorcycle-scaled.jpg"),
        description: "What started as an idea 3 years ago to bring the biking fraternity under a common experiential and bonding platform is a reality today. The Big Biking Commune has journeyed from just a possibility to becoming one of the largest networking platform for bikers to come together; make new biker friends, share riding experience, learn from veteran bikers, immerse in the ambience.",
        route: "Thank "

    },
]